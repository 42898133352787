<template>
    <div>
        <b-button variant="primary" class="btn-lg mt-5" :to="{ name: 'Profile' }">
        Fill my KYC Verification
      </b-button>
      <Empty text="Please fill our KYC to continue" />
       <!--  <div class="text-left">
            <b-button variant="primary" style="width:100px" class="mr-3">
                Buy
            </b-button>
            <b-button variant="outline-secondary" style="width:100px">
                Sell
            </b-button>
        </div>
        <div class="mt-3">
            <b-tabs content-class="mt-3" lazy>
                <b-tab title="USDT" active>
                    
                </b-tab>
                <b-tab title="BTC"><p>I'm the second tab</p></b-tab>
                <b-tab title="BUSD"><p>I'm a disabled tab!</p></b-tab>
            </b-tabs>
        </div>
        <h2>
            p2P
        </h2> -->
    </div>
</template>